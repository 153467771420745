import { createContext, useContext, useEffect, useState } from "react";
import { useAuthenticated } from "../hooks/useAuthenticated";

// Create a context for subscription status
const SubscriptionContext = createContext();

// Custom hook to use the SubscriptionContext
export const useSubscription = () => useContext(SubscriptionContext);

// SubscriptionProvider component to wrap around the App
export const SubscriptionProvider = ({ children }) => {
  const [isSubscriptionExpired, setSubscriptionExpired] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const isAuthenticated = useAuthenticated();

  // Simulate checking subscription status from an API or server
  useEffect(() => {
    const checkSubscription = () => {
      const trial_data_string = localStorage.getItem("trial_info");
      let trial_data;

      if (trial_data_string !== null) {
        // Only parse if the string is not null
        trial_data = JSON.parse(trial_data_string);
      }

      if (
        !trial_data?.subscription_enable &&
        new Date(trial_data?.subs_end_date).setUTCHours(0, 0, 0, 0) < new Date()
      ) {
        setSubscriptionExpired(true);
        setIsShow(true);
      } else {
        setSubscriptionExpired(false);
        setIsShow(false);
      }
      // Replace with actual API call to check subscription status
    };

    if (isAuthenticated) {
      checkSubscription();
    }
  }, [isAuthenticated]);

  // Function to handle user clicks
  const handleUserClick = (e) => {
    if (isSubscriptionExpired) {
      e.preventDefault();
      e.stopPropagation();
      setIsShow(true);
    }
  };

  return (
    <SubscriptionContext.Provider
      value={{ isSubscriptionExpired, handleUserClick, setIsShow, isShow }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

// Fake API call function to simulate checking subscription status
