import moment from "moment";
import { useEffect, useState } from "react";
import CloseBtnIcon from "../Modal/CloseBtnIcon";
import "./styles.scss";

const TrialBanner = () => {
  const [bannerVisible, setBannerVisible] = useState(false);
  const [bannerDismissed, setBannerDismissed] = useState(false);
  const data = localStorage.getItem("trial_info");
  const trial_data = JSON.parse(data);

  const upgrade_link = process.env.REACT_APP_PAYMENT_LINK;

  useEffect(() => {
    if (trial_data) {
      const { is_trial, subscription_enable, days_left } = trial_data;

      // Show the banner if it's a trial, or if subscription is enabled and less than 15 days are left
      if (is_trial || (subscription_enable && days_left < 15)) {
        setBannerVisible(true);
      }
    }
  }, [trial_data]);

  return (
    <>
      {!bannerDismissed && bannerVisible && (
        <div className="trial-banner">
          <div></div>
          <div>
            Your {trial_data?.is_trial ? "Trial" : "Subscription"} ends on{" "}
            {moment(trial_data?.subs_end_date).format("MMM D, YYYY")}. &nbsp;
            <a
              style={{ textDecoration: "underline" }}
              href={upgrade_link}
              target="_blank"
              rel="noreferrer"
            >
              Click here
            </a>{" "}
            &nbsp;to {trial_data?.is_trial ? "upgrade" : "renew"} now.
          </div>

          <div
            className="trial-icon-div cursor-pointer"
            onClick={() => setBannerDismissed(true)}
          >
            <CloseBtnIcon />
          </div>
        </div>
      )}
    </>
  );
};

export default TrialBanner;
